var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var initializer = function () {
  configureSelect2(document);
  $(document).on("has_many_add:after", function (event, container) {
    configureSelect2(container);
  });

  function configureSelect2(container) {
    if (window.ActiveadminAddons.config.defaultSelect == "select2") {
      $("select:not(.default-select)", container).each(function (i, el) {
        setupSelect2(el);
      });
    }

    $("select.select2", container).each(function (i, el) {
      setupSelect2(el);
    });

    function setupSelect2(select) {
      var selectConfig = {
        placeholder: "",
        width: "80%",
        allowClear: true
      };

      function isFilter(path) {
        return $(select).closest(path).length > 0;
      }

      if (isFilter(".select_and_search")) {
        selectConfig.width = "resolve";
        selectConfig.allowClear = false;
      } else if (isFilter(".filter_select")) {
        selectConfig.width = "resolve";
      }

      $(select).select2(selectConfig); // Related with https://github.com/select2/select2/issues/3320

      $(select).on("select2:unselecting", function () {
        $(this || _global).data("unselecting", true);
      }).on("select2:open", function () {
        if ($(this || _global).data("unselecting")) {
          $(this || _global).select2("close").removeData("unselecting");
        }
      });
    }
  }
};

$(initializer);
$(document).on("turbolinks:load", initializer);
export default {};